<template>
  <div class="ui grid container-2">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <h1>Upgrade <span>your TayoCash Account
            and </span> provide <span>these services!</span>
          </h1>

          <div class="item">
            <p>Cash In, Cash Out </p>
            <span>Accept Cash In and Cash Out requests </span>
          </div>

          <div class="item">
            <p>Buy Load</p>
            <span>Sell telco and gaming load credits to earn more</span>
          </div>

          <div class="item">
            <p>Pay QR</p>
            <span>Provide contactless payment solutions via QR technology for your online business or physical store</span>
          </div>

          <div class="item">
            <p>Pay Bills</p>
            <span>Accept bills payments for over 200 billers nationwide</span>
          </div>

          <div class="item">
            <p>Domestic Remittance</p>
            <span>Provide remittance services nationwide through your mobile phone</span>
          </div>
        </div>
      </div>

      <div class="column div-2">
        <img class="mid-phone" src="@/assets/pd2.png" alt="Tayocash">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content2'
}
</script>

<style scoped lang='scss'>
</style>
